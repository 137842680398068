<template>
  <div class="kt-portlet">
    <div class="bubbly-button" @click="onClick" ref="button">
      <b-button> Select! </b-button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    onClick() {
      this.$refs["button"].classList.remove("animate");
      this.$refs["button"].classList.add("animate");

      setTimeout(() => this.$refs["button"].classList.remove("animate"), 300);
    },
  },
};
</script>
